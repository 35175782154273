import React from 'react';
import { BooleanInput } from 'react-admin';
import { Box } from '@material-ui/core';

const PermissionsField = ({ record, source }) => {
  let permissions = []
  Object.entries(record[source]).map(item => (
    permissions.push(item[0])
  ))

  return (
    <Box display='flex' flexWrap="wrap" style={{ maxWidth: '700px' }}>
      {permissions.map(item => (  
        <Box flex={1} style={{ minWidth: '200px' }}>
          <BooleanInput source={`permissions[${item}]`} key={item} label={item} />
        </Box>
      ))}
    </Box>
  );
}

export default PermissionsField;