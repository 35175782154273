import React from 'react';
import FilingFeeCalculator from './FilingFeeCalculator';

import { useFormState } from 'react-final-form';

const FilingFeeFormulaOnly = ({ record, source }) => {
  const { values } = useFormState();

  if (!values[source]) {
    values[source] = []
  }

  return (
    <div>
      <FilingFeeCalculator source="formula" formula={values[source]} record={values} type="formula" />
    </div>
  );
}

export default FilingFeeFormulaOnly;