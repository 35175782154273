import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, required, useNotify, useRefresh, useRedirect, Pagination, FormDataConsumer, SelectArrayInput, ReferenceArrayInput, ChipField, choices, TabbedForm, FormTab } from 'react-admin';
import CustomModal from '../CustomModal';
import CustomToolbar from '../CustomToolbar';
import PermissionsField from '../PermissionsField';
import ActivateButton from '../ActivateButton';
import ResetPasswordButton from '../ResetPasswordButton';
import Cookies from 'js-cookie';
import { FilingFeeListActions } from '../CustomTopToolbar';
import FilingFeeAdditional from '../FilingFeeAdditional';
import FilingFeeTable from '../FilingFeeTable';
import FilingFeeFormulaOnly from '../FilingFeeFormulaOnly';

const LitigationTitle = ({ record }) => {
  return <span>Litigation {record ? `"${record.pleading}"` : ''}</span>;
};

const LitigationPanel = ({ id, record, resource }) => (
  <div style={{ flex: 1, flexDirection: 'row', width: 1000 }}>
    <div>
      <b>Description:</b> <div dangerouslySetInnerHTML={{ __html: record.description }} style={{ width: '1000px' }} />
      <b>Courts:</b> <br />
      {record.courts.map(x => <div>{x.name}</div>)}
      <b>Variable A:</b> {record.variableA} <br />
      <b>Variable B:</b> {record.variableB} <br />
      <b>Variable C:</b> {record.variableC} <br />
    </div>
  </div>
);

const LitigationFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
    <SelectInput label="Status" source="status" choices={[
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'CREATED', name: 'CREATED' },
      { id: 'INACTIVE', name: 'INACTIVE' },
    ]} />
    <SelectInput label="Amount Type" source="amountType" choices={[
      { id: 'AMOUNT_ONLY', name: 'AMOUNT ONLY' },
      { id: 'CONDITIONAL', name: 'CONDITIONAL' },
      { id: 'FORMULA', name: 'FORMULA' },
      { id: 'PER_AMOUNT', name: 'PER AMOUNT' },
    ]} />
    {/* <SelectInput label="Additional Amount" source="hasAdditionalAmount" choices={[
      { id: 0, name: 'YES' },
      { id: 1, name: 'NO' },
    ]} /> */}
  </Filter>
);

const LitigationPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const LitigationList = props => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} actions={<FilingFeeListActions route="court" />} filters={<LitigationFilter />} pagination={<LitigationPagination />} exporter={false} {...props}>
      <Datagrid expand={<LitigationPanel />}>
        <TextField source="id" />
        <TextField source="pleading" />
        <TextField source="amountType" />
        {/* <ArrayField source="courts"><SingleFieldList><ChipField source="id" /></SingleFieldList></ArrayField> */}
        <BooleanField source="hasLegalResearchFee" label="Legal research fee" />
        <TextField source="amount" />
        {/* <TextField source="formula" /> */}
        {/* <TextField source="conditionalTable" /> */}
        {/* <TextField source="additionalFees" /> */}
        <TextField source="status" />
        <TextField source="isNew" label="New" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        {permissions['write filing fee'] ? <EditButton /> : null}
        {permissions['write filing fee'] ? <ActivateButton table='litigation' name='username' /> : null}
      </Datagrid>
    </List>
  );
}

const feeWhichHigherValidation = (value, allValues) => {
  const isSpacesOrUndefined = str => {
    if (str == undefined) {
      return true
    }
    else if (str.match(/^ *$/)) {
      return true
    }
    else {
      return false
    }
  }

  let feeWhichHigherVar = 'variable' + allValues.feeWhichHigher
  if (isSpacesOrUndefined(allValues[feeWhichHigherVar]) && value) {
    return `Variable ${value} is empty`;
  }
}

const minimumWhichHigherValidation = (value, allValues) => {
  const isSpacesOrUndefined = str => {
    if (str == undefined) {
      return true
    }
    else if (str.match(/^ *$/)) {
      return true
    }
    else {
      return false
    }
  }

  let minimumWhichHigherVar = 'variable' + allValues.minimumWhichHigher
  if (isSpacesOrUndefined(allValues[minimumWhichHigherVar]) && value) {
    return `Variable ${value} is empty`;
  }
}

const validateFeeWhichHigher = [feeWhichHigherValidation]
const validateMinimumWhichHigher = [minimumWhichHigherValidation]

const isEmptyOrSpaces = str => {
  return str === null || str.match(/^ *$/) !== null;
}

const emptyToNull = str => {
  if (isEmptyOrSpaces(str)) {
    return null
  }
  else {
    return str
  }
}

const transform = data => {
  if (data.variableA) {
    data.variableA = emptyToNull(data.variableA)
  }
  if (data.variableB) {
    data.variableB = emptyToNull(data.variableB)
  }
  if (data.variableC) {
    data.variableC = emptyToNull(data.variableC)
  }

  return ({
    ...data,
    courts: data.court
  })
};

export const LitigationEdit = props => {
  return (
  <Edit title={<LitigationTitle />} {...props} undoable={false} transform={transform}>
    <TabbedForm style={{flex: 1}} variant="standard" toolbar={<CustomToolbar />}>
      <FormTab label="Information">
        <TextInput disabled source="id" />
        <TextInput multiline source="pleading" />
        <SelectInput source="amountType" choices={[
          { id: 'AMOUNT_ONLY', name: 'AMOUNT ONLY' },
          { id: 'CONDITIONAL', name: 'CONDITIONAL' },
          { id: 'FORMULA', name: 'FORMULA' },
          { id: 'PER_AMOUNT', name: 'PER AMOUNT' },
        ]} validate={[required()]} />
        <TextInput multiline source="description" fullWidth />
        <ReferenceArrayInput source="court" fullWidth reference="court" label="Courts">
          <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.amountType == 'AMOUNT_ONLY' &&
            <TextInput source="amount" {...rest} validate={[required()]} />
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.amountType == 'PER_AMOUNT' &&
            <TextInput source="amount" {...rest} validate={[required()]} />
          }
        </FormDataConsumer>
        <BooleanInput source="hasLegalResearchFee" label="Legal Research Fee" />
        <TextField disabled source="created_at" />
        <TextField disabled source="updated_at" />
      </FormTab>

      <FormTab label="Setting">
        <TextInput source="variableA" label="Variable A" />
        <TextInput source="variableB" label="Variable B" />
        <TextInput source="variableC" label="Variable C" />

        <SelectInput source="feeWhichHigher" allowEmpty choices={[
          { id: 'A', name: 'A' },
          { id: 'B', name: 'B' },
          { id: 'C', name: 'C' },
        ]} validate={validateFeeWhichHigher} />

        <SelectInput source="minimumWhichHigher" allowEmpty choices={[
          { id: 'A', name: 'A' },
          { id: 'B', name: 'B' },
          { id: 'C', name: 'C' },
        ]} validate={validateMinimumWhichHigher} />

        <TextInput source="minimumAmount" />
        <TextInput source="maximumAmount" />
      </FormTab>

      <FormTab label="Tables">
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.amountType == 'CONDITIONAL' &&
            <FilingFeeTable source="conditionalTable" {...rest} />
          }
        </FormDataConsumer>
        <FilingFeeAdditional source="additionalFees" />
      </FormTab>

      <FormDataConsumer>
        {({ formData, ...rest }) => formData.amountType == 'FORMULA' &&
          <FormTab label="Formula" {...rest}>
            <FilingFeeFormulaOnly source="formula" type="edit" {...rest} />
          </FormTab>
        }
      </FormDataConsumer>
    </TabbedForm>
  </Edit>
  )
};

export const LitigationCreate = props => {
  // todo: apply LitigationEdit changes to create
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
      notify(`Element created`)
      redirect(`/${props.resource}`);
      refresh();
  };
  
  return (
    <Create {...props} onSuccess={onSuccess} transform={transform}>
      <TabbedForm style={{flex: 1}} variant="standard" toolbar={<CustomToolbar />}>
        <FormTab label="Information">
          <TextInput multiline source="pleading" />
          <SelectInput source="amountType" choices={[
            { id: 'AMOUNT_ONLY', name: 'AMOUNT ONLY' },
            { id: 'CONDITIONAL', name: 'CONDITIONAL' },
            { id: 'FORMULA', name: 'FORMULA' },
            { id: 'PER_AMOUNT', name: 'PER AMOUNT' },
          ]} validate={[required()]} />
          <TextInput multiline source="description" fullWidth />
          <ReferenceArrayInput source="court" fullWidth reference="court" label="Courts">
            <SelectArrayInput optionText="code" />
          </ReferenceArrayInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.amountType == 'AMOUNT_ONLY' &&
              <TextInput source="amount" {...rest} validate={[required()]} />
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.amountType == 'PER_AMOUNT' &&
              <TextInput source="amount" {...rest} validate={[required()]} />
            }
          </FormDataConsumer>
          <BooleanInput source="hasLegalResearchFee" label="Legal Research Fee" defaultValue={false} />
        </FormTab>

        <FormTab label="Setting">
          <TextInput source="variableA" label="Variable A" />
          <TextInput source="variableB" label="Variable B" />
          <TextInput source="variableC" label="Variable C" />

          <SelectInput source="feeWhichHigher" allowEmpty choices={[
            { id: 'A', name: 'A' },
            { id: 'B', name: 'B' },
            { id: 'C', name: 'C' },
          ]} validate={validateFeeWhichHigher} />

          <SelectInput source="minimumWhichHigher" allowEmpty choices={[
            { id: 'A', name: 'A' },
            { id: 'B', name: 'B' },
            { id: 'C', name: 'C' },
          ]} validate={validateMinimumWhichHigher} />

          <TextInput source="minimumAmount" />
          <TextInput source="maximumAmount" />
        </FormTab>

        <FormTab label="Tables">
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.amountType == 'CONDITIONAL' &&
              <FilingFeeTable source="conditionalTable" {...rest} />
            }
          </FormDataConsumer>
          <FilingFeeAdditional source="additionalFees" />
        </FormTab>

        <FormDataConsumer>
          {({ formData, ...rest }) => formData.amountType == 'FORMULA' &&
            <FormTab label="Formula" {...rest}>
              <FilingFeeFormulaOnly source="formula" type="create" {...rest} />
            </FormTab>
          }
        </FormDataConsumer>
      </TabbedForm>
    </Create>
  )
}