import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRedirect, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';

const ResetPasswordButton = ({ record, table, name }) => {
  const [open, setOpen] = React.useState(false);
  const [userPassword, setUserPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleUserPassword = (e) => {
    setUserPassword(e.target.value)
  }
  const handleNewPassword = (e) => {
    setNewPassword(e.target.value)
  }

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const reset = () => {
    dataProvider.resetPassword(table, { id: record.id, data: { userPassword: userPassword, newPassword: newPassword } })
    .then(response => {
        refresh();
        notify(`Password reset successful.`);
    })
    .catch(error => {
        notify(`Error: ${error.message}`, 'warning');
    })
    setOpen(false)
  }
  
  return (
    <div>
      <Button startIcon={<LockIcon />} size="small" color="primary" onClick={handleClickOpen}>
        Reset Password
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">RESET PASSWORD</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please fill in the required fields.
          </DialogContentText>
          <TextField
            name="userPassword"
            onChange={handleUserPassword}
            label="Your Password"
            type="password"
            required
          />
          <br />
          <TextField
            name="newPassword"
            onChange={handleNewPassword}
            label="New Password"
            type="password"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={reset} color="primary" autoFocus>
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResetPasswordButton;