import Cookies from "js-cookie";
import axios from "axios";

// const apiUrl = 'https://api.legalaide.co/api';
// const apiUrl = "https://legal-aide-api-dev.test/api";
// export const apiUrl = "http://192.168.100.232:8000/api";
export const apiUrl = "https://legal-api.minalindialysiscenter.com/api";
// export const apiUrl = "http://legal-aide-api.test/api";
const isLive = false;

const getAdmin = (token) => {
  const request = new Request(`${apiUrl}/admin`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => {
      Cookies.set("permissions", response.message.permissions, {
        secure: isLive,
        sameSite: "strict",
      });
      Cookies.set("role", response.message.role, {
        secure: isLive,
        sameSite: "strict",
      });
      Cookies.set("username", response.message.username, {
        secure: isLive,
        sameSite: "strict",
      });
      window.location.reload();
    });
};

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(`${apiUrl}/admin/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        getAdmin(response.message.token);
        Cookies.set("token", response.message.token, {
          secure: isLive,
          sameSite: "strict",
        });
        Cookies.set("permissions", "{}", {
          secure: isLive,
          sameSite: "strict",
        });
        Cookies.set("role", "loading", { secure: isLive, sameSite: "strict" });
        Cookies.set("username", "loading", {
          secure: isLive,
          sameSite: "strict",
        });
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    const requestConfig = {
      method: "post",
      url: `${apiUrl}/logout`,
      data: {}, // Axios automatically sets the Content-Type to application/json
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    };

    return axios(requestConfig)
      .then((response) => {
        // Axios response object includes a `status` field
        if (response.status == 401) {
          return Promise.resolve();
        } else if (response.status < 200 || response.status >= 300) {
          // Handle non-2xx status codes
          // You might want to throw an error or return a rejected promise here
        }
        // Handle successful logout
        // You might want to clear cookies or redirect the user here
        Cookies.remove("token", { secure: isLive });
        Cookies.remove("permissions", { secure: isLive });
        Cookies.remove("role", { secure: isLive });
        Cookies.remove("username", { secure: isLive });
      })
      .catch((error) => {
        // Handle errors
        // Axios wraps the response in error.response
        if (error.response && error.response.status == 401) {
          // Specific handling for 401 errors
        } else {
          // General error handling
        }
      });
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      Cookies.remove("token", { secure: isLive });
      Cookies.remove("permissions", { secure: isLive });
      Cookies.remove("role", { secure: isLive });
      Cookies.remove("username", { secure: isLive });
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return Cookies.get("token") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const permissions = Cookies.get("permissions");
    return permissions
      ? Promise.resolve(JSON.parse(permissions))
      : Promise.reject();
  },
};
