import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, required, useNotify, useRefresh, useRedirect, Pagination } from 'react-admin';
import MarkdownInput from 'ra-input-markdown';
import CustomUrlField from '../CustomUrlField';
import ActivateButton from '../ActivateButton';
import CustomToolbar from '../CustomToolbar';
import Cookies from 'js-cookie';

const LawTitle = ({ record }) => {
  return <span>Law {record ? `"${record.id}"` : ''}</span>;
};

const LawPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.summary }} style={{ width: '1000px' }} />
);

const LawFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
    <SelectInput label="Status" source="status" choices={[
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'CREATED', name: 'CREATED' },
      { id: 'INACTIVE', name: 'INACTIVE' },
    ]} />
    <SelectInput label="Category" source="category" choices={[
      { id: 'LAW', name: 'LAW' },
      { id: 'JURISPRUDENCE', name: 'JURISPRUDENCE' },
    ]} />
    <ReferenceInput label="Area" source="area_id" reference="area">
      <SelectInput optionText="code" />
    </ReferenceInput>
  </Filter>
);

const LawPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const LawList = props => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} filters={<LawFilter />} pagination={<LawPagination />} exporter={false} {...props}>
      <Datagrid expand={<LawPanel />}>
      <TextField source="id" />
        <TextField source="title" />
        <CustomUrlField source="link" />
        <DateField source="date" />
        <TextField source="category" />
        <ReferenceField source="area_id" reference="area"><TextField source="id" /></ReferenceField>
        <TextField source="area_code" />
        <TextField source="area_description" />
        <TextField source="sub_area" />
        <TextField source="status" />
        <TextField source="actived_at" label="Activated at" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <TextField source="isActivatedNow" label="Activated" />
        {permissions['write law'] ? <EditButton /> : null}
        {permissions['write law'] ? <ActivateButton table='law' name='title' /> : null}
      </Datagrid>
    </List>
  );
}

export const LawEdit = props => (
  <Edit title={<LawTitle />} {...props} undoable={false}>
    <SimpleForm style={{flex: 1}} variant="standard" toolbar={<CustomToolbar />}>
      <TextInput source="id" disabled />
      <TextInput source="title" validate={[required()]} />
      {/* <TextInput multiline source="summary" fullWidth validate={[required()]} /> */}
      <MarkdownInput source="summary" fullWidth validate={[required()]} />
      <TextInput source="link" validate={[required()]} />
      <DateInput source="date" validate={[required()]} />
      <SelectInput label="Category" source="category" choices={[
        { id: 'LAW', name: 'LAW' },
        { id: 'JURISPRUDENCE', name: 'JURISPRUDENCE' },
      ]} />
      <ReferenceInput label="Area" source="area_id" reference="area">
        <SelectInput optionText="code" />
      </ReferenceInput>
      <TextInput source="sub_area" />
      <TextField source="created_at" />
      <TextField source="updated_at" />
    </SimpleForm>
  </Edit>
);

export const LawCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
      notify(`Element created`)
      redirect(`/${props.resource}`);
      refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm variant="standard">
        <TextInput source="title" validate={[required()]} />
        {/* <TextInput multiline source="summary" fullWidth validate={[required()]} /> */}
        <MarkdownInput source="summary" fullWidth validate={[required()]} />
        <TextInput source="link" validate={[required()]} />
        <DateInput source="date" validate={[required()]} />
        <SelectInput label="Category" source="category" choices={[
          { id: 'LAW', name: 'LAW' },
          { id: 'JURISPRUDENCE', name: 'JURISPRUDENCE' },
        ]} />
        <ReferenceInput label="Area" source="area_id" reference="area">
          <SelectInput optionText="code" />
        </ReferenceInput>
        <TextInput source="sub_area" />
      </SimpleForm>
    </Create>
  )
}