import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, NumberInput, required, BooleanInput, useNotify, useRefresh, useRedirect, Pagination } from 'react-admin';
import CustomModal from '../CustomModal';
import CustomToolbar from '../CustomToolbar';
import PermissionsField from '../PermissionsField';
import ActivateButton from '../ActivateButton';
import { Box } from '@material-ui/core';
import Cookies from 'js-cookie';

const PlanTitle = ({ record }) => {
  return <span>Plan {record ? `"${record.name}"` : ''}</span>;
};

const PlanPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.description }} style={{ width: '1000px' }} />
);

const PlanPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const PlanFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
    <SelectInput label="Interval" source="interval" choices={[
      { id: 'DAY', name: 'DAY' },
      { id: 'MONTH', name: 'MONTH' },
      { id: 'YEAR', name: 'YEAR' },
    ]} />
    <SelectInput label="Status" source="status" choices={[
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'CREATED', name: 'CREATED' },
      { id: 'INACTIVE', name: 'INACTIVE' },
    ]} />
  </Filter>
);

export const PlanList = (props) => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} filters={<PlanFilter />} pagination={<PlanPagination />} exporter={false} {...props}>
      <Datagrid expand={<PlanPanel />}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="status" />
        <TextField source="amount" />
        <TextField source="interval" />
        <NumberField source="interval_count" />
        <NumberField source="trial_period_in_days" />
        {/* <CustomModal source="permissions" /> */}
        <TextField source="placePermission" />
        <TextField source="checklistPermission" />
        <TextField source="hasLegalUpdates" />
        <TextField source="isAdsFree" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        {permissions['write plan'] ? <EditButton /> : null}
        {permissions['write plan'] ? <ActivateButton table='plan' name='name' /> : null}
      </Datagrid>
    </List>
  );
}

export const PlanEdit = props => (
  <Edit title={<PlanTitle />} {...props} undoable={false}>
    <SimpleForm variant="standard" toolbar={<CustomToolbar />}>
      <TextInput disabled source="id" />
      <TextInput disabled source="name" />
      <TextInput multiline source="description" fullWidth validate={[required()]} />
      <TextInput disabled source="status" />
      <TextInput disabled source="amount" />
      <TextInput disabled source="interval" />
      <TextInput disabled source="interval_count" />
      <TextInput disabled source="trial_period_in_days" />
      {/* <PermissionsField source="permissions" /> */}
      <SelectInput source="placePermission" choices={[
        { id: 'FREE', name: 'FREE' },
        { id: 'BASIC', name: 'BASIC' },
        { id: 'ADVANCE', name: 'ADVANCE' },
      ]} validate={[required()]} />
      <SelectInput source="checklistPermission" choices={[
        { id: 'FREE', name: 'FREE' },
        { id: 'BASIC', name: 'BASIC' },
        { id: 'ADVANCE', name: 'ADVANCE' },
      ]} validate={[required()]} />
      <BooleanInput source="hasLegalUpdates" validate={[required()]} />
      <BooleanInput source="isAdsFree" validate={[required()]} />
      <TextField disabled source="created_at" />
      <TextField disabled source="updated_at" />
    </SimpleForm>
  </Edit>
);

export const PlanCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
      notify(`Element created`)
      redirect(`/${props.resource}`);
      refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm variant="standard" toolbar={<CustomToolbar />}>
        <TextInput source="name" validate={[required()]} />
        <TextInput multiline source="description" validate={[required()]} />
        <TextInput source="amount" validate={[required()]} />
        <SelectInput label="Interval" source="interval" choices={[
          { id: 'DAY', name: 'DAY' },
          { id: 'MONTH', name: 'MONTH' },
          { id: 'YEAR', name: 'YEAR' },
        ]} validate={[required()]} />
        <TextInput source="interval_count" validate={[required()]} />
        <NumberInput source="trial_period_in_days" validate={[required()]} />
        {/* <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '250px' }}>
            <BooleanInput source='permissions[search local directory]' label='search local directory' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '250px' }}>
            <BooleanInput source='permissions[search all directory]' label='search all directory' defaultValue={true} />
          </Box>
        </Box> */}
        <SelectInput source="placePermission" choices={[
          { id: 'FREE', name: 'FREE' },
          { id: 'BASIC', name: 'BASIC' },
          { id: 'ADVANCE', name: 'ADVANCE' },
        ]} validate={[required()]} />
        <SelectInput source="checklistPermission" choices={[
          { id: 'FREE', name: 'FREE' },
          { id: 'BASIC', name: 'BASIC' },
          { id: 'ADVANCE', name: 'ADVANCE' },
        ]} validate={[required()]} />
        <BooleanInput source="hasLegalUpdates" validate={[required()]} defaultValue={false} />
        <BooleanInput source="isAdsFree" validate={[required()]} defaultValue={false} />
      </SimpleForm>
    </Create>
  )
}