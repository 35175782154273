import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: 17.5,
    marginTop: 10
  },
}));

const FilingFeeCalculator = (props) => {
  React.useEffect(() => {
    if (!props.formula) {
      clearAll()
    }

    if (props.formula.length > 0) {
      let lastVar = props.formula[props.formula.length - 1]

      if (lastVar == '/' || lastVar == '*' || lastVar == '-' || lastVar == '+') {
        setTypes('operator')
      }
      else if (lastVar == '(') {
        setTypes('openParen')
      }
      else if (lastVar == ')') {
        setTypes('closeParen')
      }
      else {
        setTypes('value')
      }
    }
    else {
      setTypes('operator')
    }
  }, [])

  const [currentType, setCurrentType] = React.useState('')
  const [previousType, setPreviousType] = React.useState('')
  const [addValue, setAddValue] = React.useState('')
  const [formuler, setFormuler] = React.useState(JSON.parse(JSON.stringify(props.formula)))

  const classes = useStyles();

  const isEmptyOrSpaces = str => {
    return str === null || str.match(/^ *$/) !== null;
  }

  const setTypes = type => {
    setCurrentType(type)
    setPreviousType(type)
  }

  const pushVar = (value, type) => {
    if (currentType == 'value' && type == 'operator') {
      props.formula.push(value)
    }
    else if (currentType == 'value' && type == 'closeParen') {
      props.formula.push(value)
    }
    else if (currentType == 'operator' && type == 'value') {
      props.formula.push(value)
    }
    else if (currentType == 'operator' && type == 'openParen') {
      props.formula.push(value)
    }
    else if (currentType == 'openParen' && type == 'value' ) {
      props.formula.push(value)
    }
    else if (currentType == 'closeParen' && type == 'operator' ) {
      props.formula.push(value)
    }
    else if (currentType == 'openParen' && type == 'openParen' ) {
      props.formula.push(value)
    }
    else if (currentType == 'closeParen' && type == 'closeParen' ) {
      props.formula.push(value)
    }
    else if (props.type == 'formula' && currentType == 'value' && type == 'value') {
      props.formula.push(value)
    }
    else {
      return
    }

    setFormuler(JSON.parse(JSON.stringify(props.formula)))
    setPreviousType(currentType)
    setCurrentType(type)
  }

  const clearVar = () => {
    if (props.formula.length < 1) {
      setCurrentType('operator')
    }
    else {
      setCurrentType(previousType)
    }
    props.formula.pop()
    setFormuler(JSON.parse(JSON.stringify(props.formula)))
  }

  const clearAll = () => {
    setCurrentType('operator')
    props.formula.length = 0
    setFormuler(JSON.parse(JSON.stringify(props.formula)))
  }

  return (
    <div style={{ marginTop: 30, width: 525 }}>
      <TextField
        autoFocus
        variant="outlined"
        margin="dense"
        type="text"
        fullWidth
        value={formuler.join(' ')}
        disabled
      />
      
      <Button onClick={() => pushVar('/', 'operator')} color="primary" variant="contained" className={classes.button}>
        /
      </Button>
      <Button onClick={() => pushVar('*', 'operator')} color="primary" variant="contained" className={classes.button}>
        *
      </Button>
      <Button onClick={() => pushVar('-', 'operator')} color="primary" variant="contained" className={classes.button}>
        -
      </Button>
      <Button onClick={() => pushVar('+', 'operator')} color="primary" variant="contained" className={classes.button}>
        +
      </Button>
      <Button onClick={() => pushVar('(', 'openParen')} color="primary" variant="contained" className={classes.button}>
        (
      </Button>
      <Button onClick={() => pushVar(')', 'closeParen')} color="primary" variant="contained" className={classes.button}>
        )
      </Button>
      
      <br />

      {
        props.type == 'conditional' ? 
          <span>
            <Button onClick={() => pushVar('inputValue', 'value')} color="primary" variant="contained" className={classes.button}>
              INPUT VALUE
            </Button> 
            <span style={{ marginRight: 186 }}></span>
          </span> : null
      }
      
      {
        props.type == 'formula' ? 
          <span>
            <Button onClick={() => pushVar('A', 'value')} color="primary" variant="contained" className={classes.button} disabled={!props.record.variableA}>
              A
            </Button>
            <Button onClick={() => pushVar('B', 'value')} color="primary" variant="contained" className={classes.button} disabled={!props.record.variableB}>
              B
            </Button>
            <Button onClick={() => pushVar('C', 'value')} color="primary" variant="contained" className={classes.button} disabled={!props.record.variableC}>
              C
            </Button>
            <span style={{ marginRight: 82 }}></span>
          </span> : null
      }

      <Button onClick={() => clearVar()} color="primary" variant="contained" className={classes.button}>
        <KeyboardBackspaceIcon />
      </Button>
      <Button onClick={() => clearAll()} color="primary" variant="contained" className={classes.button} style={{ paddingTop: 10, paddingBottom: 9 }}>
        <span style={{ fontSize: 10 }}>CLEAR</span>
      </Button>

      <br />

      <TextField
        autoFocus
        variant="outlined"
        margin="dense"
        id="name"
        type="number"
        placeholder="value"
        value={addValue}
        onChange={event => setAddValue(event.target.value)}
        style={{ marginRight: 10 }}
      />
      <Button onClick={() => pushVar(parseFloat(addValue), 'value')} color="primary" variant="contained" className={classes.button}>
        ADD VALUE
      </Button>
    </div>
  )
}

export default FilingFeeCalculator;