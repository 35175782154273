import * as React from "react";
import { cloneElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

export const FilingFeeListActions = (props) => {
  const {
      className,
      exporter,
      filters,
      maxResults,
      ...rest
  } = props;
  const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
  } = useListContext();
  return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
          {filters && cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
          })}
          <CreateButton basePath={basePath} />
          {/* Add your custom actions */}
          <Button
              component={Link}
              to={{ pathname: `/${props.route}` }}
              label="Settings"
          >
              <SettingsIcon />
          </Button>
      </TopToolbar>
  );
};