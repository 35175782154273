import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRedirect, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';

const ResetPasswordButton = ({ record, table, name }) => {
  const [open, setOpen] = React.useState(false);
  const [userPassword, setUserPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button startIcon={<VisibilityIcon />} size="small" color="primary" onClick={handleClickOpen}>
        Show
      </Button>
      <Dialog
        style={{ margin: 50 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{record.name}</DialogTitle>
        <DialogContent>
          <center>
            <img src={record.picture1} style={{ maxWidth: '100%' }} /><br />
            {
              record.pictures ?
              record.pictures.map(x => {
                return (
                  <img src={x} style={{ maxWidth: '100%' }} />
                )
              }) : null
            }
          </center>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResetPasswordButton;