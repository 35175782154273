import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, required, useNotify, useRefresh, useRedirect, Pagination } from 'react-admin';
import Cookies from 'js-cookie';

const AreaTitle = ({ record }) => {
  return <span>Area {record ? `"${record.code}"` : ''}</span>;
};

const AreaFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
  </Filter>
);

const AreaPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const AreaList = props => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} filters={<AreaFilter />} pagination={<AreaPagination />} exporter={false} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="code" />
        <TextField source="description" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        {permissions['write law'] ? <EditButton /> : null}
      </Datagrid>
    </List>
  );
}

export const AreaEdit = props => (
  <Edit title={<AreaTitle />} {...props} undoable={false}>
    <SimpleForm style={{flex: 1}} variant="standard">
      <TextInput source="id" disabled />
      <TextInput source="code" validate={[required()]} />
      <TextInput source="description" validate={[required()]} />
      <TextField source="created_at" />
      <TextField source="updated_at" />
    </SimpleForm>
  </Edit>
);

export const AreaCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
      notify(`Element created`)
      redirect(`/${props.resource}`);
      refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm variant="standard">
        <TextInput source="code" validate={[required()]} />
        <TextInput source="description" validate={[required()]} />
      </SimpleForm>
    </Create>
  )
}