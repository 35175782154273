import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useFormState } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FilingFeeAdditional = ({ record, source }) => {
  const { values } = useFormState();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const [rowData, setRowData] = React.useState({
    name: null,
    description: null,
    amountType: 'AMOUNT_ONLY',
    amount: null,
    isOptional: true
  })
  const [rowName, setRowName] = React.useState('')
  const [rowIndex, setRowIndex] = React.useState(null)

  const handleClickOpen = (data, index) => {
    if (data) {
      setRowData(data)
      setRowName(data.name)
      setRowIndex(index)
    }
    else {
      setRowData({
        name: null,
        description: null,
        amountType: 'AMOUNT_ONLY',
        amount: null,
        isOptional: true,
        claimTable: []
      })
      setRowIndex(null)
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDelete = (row) => {
    setRowData(row)
    setRowName(row.name)
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const isEmptyOrSpaces = str => {
    return str === null || str.match(/^ *$/) !== null;
  }

  const emptyToNull = str => {
    if (isEmptyOrSpaces(str)) {
      return null
    }
    else {
      return str
    }
  }

  const addClaimTableRow = () => {
    setRowData({...rowData, claimTable: [...rowData.claimTable, {
      index: null,
      from: null,
      to: null,
      amount: null
    }]})
  }

  const deleteClaimTableRow = (row) => {
    let data = rowData.claimTable.filter(x => x != row)
    setRowData({...rowData, claimTable: data})
  }

  const deleteRow = () => {
    record[source] = record[source].filter(x => x != rowData)
    values[source] = record[source]
    handleCloseDelete()
  }

  const save = (data) => {
    if (data.amountType == 'AMOUNT_ONLY') {
      delete data.userDefineKey
      delete data.claimKey
      delete data.claimTable

      if (isEmptyOrSpaces(data.amount) || isEmptyOrSpaces(data.name)) {
        alert('Please fill in the required fields.')
        return
      }
    }
    else if (data.amountType == 'USER_DEFINE') {
      delete data.amount
      delete data.claimKey
      delete data.claimTable

      if (isEmptyOrSpaces(data.userDefineKey) || isEmptyOrSpaces(data.name)) {
        alert('Please fill in the required fields.')
        return
      }
    }
    else if (data.amountType == 'BY_CLAIM') {
      delete data.amount
      delete data.userDefineKey

      if (isEmptyOrSpaces(data.claimKey) || isEmptyOrSpaces(data.name)) {
        alert('Please fill in the required fields.')
        return
      }
      for (let i in data.claimTable) {
        if (isEmptyOrSpaces(data.claimTable[i].index) || isEmptyOrSpaces(data.claimTable[i].amount)) {
          alert('Please fill in the required fields.')
          return
        }
      }

      for (let i = 0; i < data.claimTable.length; i++) {
        data.claimTable[i].from = emptyToNull(data.claimTable[i].from)
        data.claimTable[i].to = emptyToNull(data.claimTable[i].to)
      }
    }

    if (data.id) {
      record[source] = record[source].map(x => {
        if (x.id == data.id) {
          return data
        }
        else {
          return x
        }
      });
      values[source] = record[source]
    }
    else if (data && rowIndex) {
      record[source][rowIndex] = data
      values[source][rowIndex] = record[source][rowIndex]
    }
    else {
      record[source].push(data)
      values[source] = record[source]
    }

    setOpen(false);
  }

  const classes = useStyles();

  if (!record.additionalFees) {
    record.additionalFees = []
  }

  return (
    <div>
      <div style={{ margin: 10 }}>
        <b>Additional Fees</b>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Amount Type</TableCell>
              <TableCell>Optional</TableCell>
              <TableCell align="right">
                <Button startIcon={<AddIcon />} size="small" color="primary" onClick={() => handleClickOpen()}>
                  New
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.additionalFees.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.amountType}</TableCell>
                <TableCell>{JSON.stringify(row.isOptional)}</TableCell>
                <TableCell align="right">
                  <Button startIcon={<CreateIcon />} size="small" color="primary" onClick={() => handleClickOpen(row, index)}>
                    Edit
                  </Button>
                  <Button style={{ float: 'right', color: '#f44336' }} startIcon={<DeleteIcon />} size="small" onClick={() => handleClickOpenDelete(row)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
        <DialogTitle id="form-dialog-title">{rowData ? `Edit ${rowName}` : 'Create'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={rowData.name}
            onChange={event => setRowData({...rowData, name: event.target.value})}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="name"
            label="Description"
            type="text"
            value={rowData.description}
            onChange={event => setRowData({...rowData, description: event.target.value})}
            fullWidth
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Amount Type</InputLabel>
            <Select
              value={rowData.amountType}
              onChange={event => {
                if (event.target.value == 'BY_CLAIM') {
                  if (!rowData.claimTable) {
                    setRowData({...rowData, amountType: event.target.value, claimTable: []})
                  }
                  else {
                    setRowData({...rowData, amountType: event.target.value})
                  }
                }
                else {
                  setRowData({...rowData, amountType: event.target.value})
                }
              }}
            >
              <MenuItem value='AMOUNT_ONLY'>AMOUNT ONLY</MenuItem>
              <MenuItem value='USER_DEFINE'>USER DEFINE</MenuItem>
              <MenuItem value='BY_CLAIM'>BY CLAIM</MenuItem>
            </Select>
          </FormControl>

          { rowData.amountType == 'AMOUNT_ONLY' ? 
              <TextField
                margin="dense"
                id="name"
                label="Amount"
                type="number"
                value={rowData.amount}
                onChange={event => setRowData({...rowData, amount: event.target.value})}
                fullWidth
                required
              />
            : null }
          
          { rowData.amountType == 'USER_DEFINE' ? 
              <TextField
                margin="dense"
                id="name"
                label="User Define Key"
                type="text"
                value={rowData.userDefineKey}
                onChange={event => setRowData({...rowData, userDefineKey: event.target.value})}
                fullWidth
                required
              />
            : null }

            { rowData.amountType == 'BY_CLAIM' ? 
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Claim Key"
                  type="text"
                  value={rowData.claimKey}
                  onChange={event => setRowData({...rowData, claimKey: event.target.value})}
                  fullWidth
                  required
                />
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Index</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>
                          <Button style={{ float: 'right' }} startIcon={<AddIcon />} size="small" color="primary" onClick={() => addClaimTableRow()}>
                            New
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData.claimTable.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <TextField
                              required
                              variant="outlined"
                              margin="dense"
                              type="text"
                              size="small"
                              type="number"
                              value={rowData.claimTable[index].index}
                              onChange={event => {
                                let newRow = JSON.parse(JSON.stringify(rowData))
                                newRow.claimTable[index].index = event.target.value
                                setRowData(newRow)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              type="text"
                              size="small"
                              type="number"
                              value={rowData.claimTable[index].from}
                              onChange={event => {
                                let newRow = JSON.parse(JSON.stringify(rowData))
                                newRow.claimTable[index].from = event.target.value
                                setRowData(newRow)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              type="text"
                              size="small"
                              type="number"
                              value={rowData.claimTable[index].to}
                              onChange={event => {
                                let newRow = JSON.parse(JSON.stringify(rowData))
                                newRow.claimTable[index].to = event.target.value
                                setRowData(newRow)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              required
                              variant="outlined"
                              margin="dense"
                              type="text"
                              size="small"
                              type="number"
                              value={rowData.claimTable[index].amount}
                              onChange={event => {
                                let newRow = JSON.parse(JSON.stringify(rowData))
                                newRow.claimTable[index].amount = event.target.value
                                setRowData(newRow)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Button style={{ float: 'right', color: '#f44336' }} startIcon={<DeleteIcon />} size="small" onClick={() => deleteClaimTableRow(row)}>
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            : null }

          <FormControlLabel className={classes.formControl}
            control={
              <Switch
                checked={rowData.isOptional}
                onChange={event => setRowData({...rowData, isOptional: !rowData.isOptional})}
                color="primary"
              />
            }
            label="Optional"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save(rowData)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete {rowName}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {rowName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteRow} style={{ color: '#f44336' }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FilingFeeAdditional;