import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRedirect, useRefresh, useInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PlaceIcon from '@material-ui/icons/Place';
import GoogleMapReact from 'google-map-react';
import SearchBox from './SearchBox';

const Marker = ({ text }) => (
  <div style={{ position: 'absolute', bottom: -5, right: 0, left: -17 }}>
    <PlaceIcon fontSize="large" color="primary" stroke={"white"} strokeWidth={1} />
  </div>
);

const SetPlaceMap = props => {
  const {
    input: { name, onChange, value },
    isRequired
  } = useInput(props);

  const [open, setOpen] = React.useState(false);
  const [marker, setMarker] = React.useState({'lat': 14.5995, 'lng': 120.9842});
  const [oldMarker, setOldMarker] = React.useState({});
  const [mapsConfig, setMapsConfig] = React.useState({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    places: [],
  })

  const handleClickOpen = () => {
    setOpen(true);
    setOldMarker(marker)
  };
  const handleClose = () => {
    setOpen(false);
    setMarker(oldMarker)
  };

  const handleMarker = (coord) => {
    const { lat, lng } = coord;

    setMarker({
      lat, lng
    })
  }

  const saveMarker = () => {
    onChange(JSON.stringify(marker))
    setOpen(false);
  }

  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      setMapsConfig({
        mapApiLoaded: true,
        mapInstance: map,
        mapApi: maps,
      });
    }
  };

  const addPlace = (place) => {
    setMapsConfig({...mapsConfig, places: place });
  };

  return (
    <div>
      <Button startIcon={<PlaceIcon />} variant="contained" size="small" color="primary" onClick={handleClickOpen}>
        Set Geo Pin
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth = {'md'}
        style={{ margin: 50, zIndex: 1 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Set Geo Pin
        </DialogTitle>
        <DialogContent style={{ height: '100vh', width: '100%', padding: 0 }}>
          {mapsConfig.mapApiLoaded && <SearchBox map={mapsConfig.mapInstance} mapApi={mapsConfig.mapApi} addplace={addPlace} />}
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBu7G17rOHAwiSbn8-WoyWAPwxg1MTD_Mw', libraries: ['places', 'geometry'] }}
            yesIWantToUseGoogleMapApiInternals
            options={{ 
              draggableCursor: 'crosshair',
              styles: [
                {
                  featureType: 'all',
                  stylers: [{ visibility: 'on' }],
                }
              ]
            }}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            defaultCenter={{lat: 14.5995, lng: 120.9842}}
            defaultZoom={20}
            onClick={handleMarker}
          >
            <Marker
              lat={marker.lat}
              lng={marker.lng}
            />
          </GoogleMapReact>
        </DialogContent>
        <DialogActions>
          <span style={{fontSize: 12, marginRight: 20}}>LATITUDE: {marker.lat} LONGITUDE: {marker.lng}</span>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={saveMarker} color="primary" autoFocus>
            Set
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SetPlaceMap;