import * as React from "react";
import { createElement } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import LabelIcon from "@material-ui/icons/Label";
import DashboardIcon from "@material-ui/icons/Dashboard";

import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import StarBorder from "@material-ui/icons/StarBorder";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
}));

const Menu = ({ onMenuClick, logout }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  const itemTextFormat = (text) => {
    if (text === "user") {
      return "Admins";
    } else if (text === "subscription") {
      return "Users";
    } else if (text === "place") {
      return "Courts & Offices";
    } else if (text === "law") {
      return "Law & Jurisprudence";
    } else {
      return text.charAt(0).toUpperCase() + text.slice(1) + "s";
    }
  };
  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "white",
        boxShadow: "1px 0px 3px #C4C4C4",
        marginRight: 10,
      }}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <MenuItemLink
          className={classes.item}
          to="/"
          primaryText="Dashboard"
          leftIcon={<DashboardIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={sidebarOpen}
          exact
        />
        {resources.map((resource) => {
          if (
            resource.name != "litigation" &&
            resource.name != "corporate" &&
            resource.name != "labor" &&
            resource.name != "court" &&
            resource.name != "category" &&
            resource.name != "filingfee" &&
            resource.name != "filing-type" &&
            resource.name != "place-type" &&
            resource.name != "fee-percentage"
          ) {
            return (
              <MenuItemLink
                className={classes.item}
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={itemTextFormat(resource.name)}
                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={sidebarOpen}
              />
            );
          } else if (resource.name == "place-type") {
            return (
              <MenuItemLink
                className={classes.item}
                key={resource.name}
                to={`/${resource.name}`}
                primaryText="Place Type"
                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={sidebarOpen}
              />
            );
          }
        })}
        {/* <MenuItemLink
                    className={classes.item}
                    to="/filingfee"
                    primaryText="Filing Fee"
                    leftIcon={<DescriptionIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={sidebarOpen}
                    exact
                /> */}
        {isXSmall && logout}
        <ListItem button onClick={handleClick}>
          <ListItemIcon style={{ minWidth: 40 }}>
            <DescriptionIcon />
          </ListItemIcon>
          {sidebarOpen ? (
            <ListItemText
              primary="Filing Fee"
              style={{ margin: 0, color: "#757575" }}
            />
          ) : null}
          {open ? (
            sidebarOpen ? (
              <ExpandLess />
            ) : null
          ) : sidebarOpen ? (
            <ExpandMore />
          ) : null}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MenuItemLink
            className={classes.item}
            className={classes.nested}
            to="/filingfee"
            primaryText="Filing Fee"
            leftIcon={<DescriptionIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={sidebarOpen}
            exact
          />
          <MenuItemLink
            className={classes.item}
            className={classes.nested}
            to="/filing-type"
            primaryText="Filing Type"
            leftIcon={<DescriptionIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={sidebarOpen}
            exact
          />
        </Collapse>
      </List>
    </div>
    // <div style={{ height: '100%', backgroundColor: 'white', boxShadow: '1px 0px 3px #C4C4C4', marginRight: 5, paddingTop: 20 }}>
    //     <MenuItemLink
    //         to="/"
    //         primaryText="Dashboard"
    //         leftIcon={<DashboardIcon />}
    //         onClick={onMenuClick}
    //         sidebarIsOpen={open}
    //         exact
    //     />
    // </div>
  );
};

export default Menu;
