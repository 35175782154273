import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, required, useNotify, useRefresh, useRedirect, Pagination } from 'react-admin';
import CustomModal from '../CustomModal';
import CustomToolbar from '../CustomToolbar';
import PermissionsField from '../PermissionsField';
import ActivateButton from '../ActivateButton';
import ResetPasswordButton from '../ResetPasswordButton';
import Cookies from 'js-cookie';

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.username}"` : ''}</span>;
};

const UserFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
    <SelectInput label="Status" source="status" choices={[
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'CREATED', name: 'CREATED' },
      { id: 'INACTIVE', name: 'INACTIVE' },
    ]} />
  </Filter>
);

const UserPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const UserList = props => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} filters={<UserFilter />} pagination={<UserPagination />} exporter={false} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="username" />
        <EmailField source="email" />
        <TextField source="status" />
        <CustomModal source="permissions" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        {permissions['write user'] ? <EditButton /> : null}
        {permissions['write user'] ? <ResetPasswordButton table='user' /> : null}
        {permissions['write user'] ? <ActivateButton table='user' name='username' /> : null}
      </Datagrid>
    </List>
  );
}

export const UserEdit = props => (
  <Edit title={<UserTitle />} {...props} undoable={false}>
    <SimpleForm style={{flex: 1}} variant="standard" toolbar={<CustomToolbar />}>
      <TextInput disabled source="id" />
      <TextInput disabled source="username" />
      <TextInput disabled source="email" />
      <PermissionsField source="permissions" />
      <TextField disabled source="created_at" />
      <TextField disabled source="updated_at" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
      notify(`Element created`)
      redirect(`/${props.resource}`);
      refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm variant="standard" toolbar={<CustomToolbar />}>
        <TextInput source="username" validate={[required()]} />
        <PasswordInput source="password" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[read plan]' label='read plan' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[write plan]' label='write plan' defaultValue={true} />
          </Box>
        </Box>
        <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[read user]' label='read user' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[write user]' label='write user' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[read subscription]' label='read subscription' defaultValue={true} />
          </Box>
        </Box>
        <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[read law]' label='read law' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[write law]' label='write law' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[delete area]' label='delete area' defaultValue={true} />
          </Box>
        </Box>
        <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[read place]' label='read place' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[write place]' label='write place' defaultValue={true} />
          </Box>
        </Box>
        <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[read checklist]' label='read checklist' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[write checklist]' label='write checklist' defaultValue={true} />
          </Box>
        </Box>
        <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[read notification]' label='read notification' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[write notification]' label='write notification' defaultValue={true} />
          </Box>
        </Box>
        <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[read filing fee]' label='read filing fee' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[write filing fee]' label='write filing fee' defaultValue={true} />
          </Box>
        </Box>
        <Box display='flex' style={{ maxWidth: '700px' }}>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[delete court]' label='delete court' defaultValue={true} />
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <BooleanInput source='permissions[delete category]' label='delete category' defaultValue={true} />
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}