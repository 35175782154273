import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, required, useNotify, useRefresh, useRedirect, Pagination, NumberInput } from 'react-admin';
import Cookies from 'js-cookie';

const NotificationTitle = ({ record }) => {
  return <span>Notification {record ? `"${record.code}"` : ''}</span>;
};

const NotificationPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.message }} style={{ width: '1000px' }} />
);

const NotificationFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
    <NumberInput label="Year" source="year" />
    <SelectInput label="Posted By" source="postedBy" choices={[
      { id: 'ADMIN', name: 'ADMIN' },
      { id: 'AUTOMATIC', name: 'AUTOMATIC' },
    ]} />
    <ReferenceInput label="Area" source="area_id" reference="area">
      <SelectInput optionText="code" />
    </ReferenceInput>
    <SelectInput label="Month" source="month" choices={[
      { id: '1', name: 'JANUARY' },
      { id: '2', name: 'FEBURARY' },
      { id: '3', name: 'MARCH' },
      { id: '4', name: 'APRIL' },
      { id: '5', name: 'MAY' },
      { id: '6', name: 'JUNE' },
      { id: '7', name: 'JULY' },
      { id: '8', name: 'AUGUST' },
      { id: '9', name: 'SEPTEMBER' },
      { id: '10', name: 'OCTOBER' },
      { id: '11', name: 'NOVEMBER' },
      { id: '12', name: 'DECEMBER' },
    ]} />
  </Filter>
);

const NotificationPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const NotificationList = props => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} filters={<NotificationFilter />} pagination={<NotificationPagination />} exporter={false} {...props}>
      <Datagrid expand={<NotificationPanel />}>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="sendBy" />
        <DateField source="postedOnDate" />
        <TextField source="postedOnTime" />
        <TextField source="area" />
        <TextField source="postedBy" />
      </Datagrid>
    </List>
  );
}

export const NotificationCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
      notify(`Element created`)
      redirect(`/${props.resource}`);
      refresh();
  };

  const transform = data => ({
    ...data,
    area_id: data.area_id ? data.area_id : null
  });

  return (
    <Create {...props} onSuccess={onSuccess} transform={transform}>
      <SimpleForm variant="standard">
        <TextInput source="title" validate={[required()]} />
        <TextInput source="message" validate={[required()]} multiline />
        <ReferenceInput label="Area" source="area_id" reference="area" allowEmpty={true}>
          <SelectInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}