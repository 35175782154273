import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, NumberInput, Pagination } from 'react-admin';
import CustomModal from '../CustomModal';
import CustomToolbar from '../CustomToolbar';
import CustomUrlField from '../CustomUrlField';
import PermissionsField from '../PermissionsField';
import ActivateButton from '../ActivateButton';

import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Button,
    sanitizeListRestProps,
} from 'react-admin';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const ListActions = (props) => {
  const {
      className,
      exporter,
      filters,
      maxResults,
      ...rest
  } = props;
  const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
  } = useListContext();
  return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
          {filters && cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
          })}
          <CreateButton basePath={basePath} />
          {/* Add your custom actions */}
          <Button
              onClick={() => { window.open("https://dashboard.xendit.co/", "_blank")}}
              label="Login to xendit"
          >
              <ExitToAppIcon />
          </Button>
      </TopToolbar>
  );
};

const SubscriptionFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
    <NumberInput label="Year" source="year" />
    <TextInput label="Plan" source="plan" />
  </Filter>
);

const SubscriptionPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const SubscriptionList = props => {
  return (
    <List bulkActionButtons={false} filters={<SubscriptionFilter />} pagination={<SubscriptionPagination />} actions={<ListActions />} exporter={false} {...props}>
      <Datagrid>
        <EmailField source="email" />
        <ReferenceField source="planId" reference="plan"><TextField source="id" /></ReferenceField>
        <TextField source="planName" />
        <TextField source="xenditId" />
        <TextField source="externalId" />
        <TextField source="startsAt" />
        <TextField source="endsAt" />
        <TextField source="isNew" label="New" />
      </Datagrid>
    </List>
  );
}