import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, required, useNotify, useRefresh, useRedirect, Pagination, NumberInput } from 'react-admin';
import MarkdownInput from 'ra-input-markdown';
import CustomUrlField from '../CustomUrlField';
import ActivateButton from '../ActivateButton';
import CustomToolbar from '../CustomToolbar';
import Cookies from 'js-cookie';

const ChecklistTitle = ({ record }) => {
  return <span>Checklist {record ? `"${record.name}"` : ''}</span>;
};

// const ChecklistPanel = ({ id, record, resource }) => {
//   return (
//     <div>
//       {record.requirements.map(x => (
//         <div>
//           -{x.index} {x.isOptional ? '(optional)' : null}<br />
//           {x.subIndex}<br />
//           {x.additionalDescription}
//         </div>
//       ))}
//     </div>
//   )
// };

const ChecklistFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
    <SelectInput label="Status" source="status" choices={[
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'CREATED', name: 'CREATED' },
      { id: 'INACTIVE', name: 'INACTIVE' },
    ]} />
    <SelectInput label="Type" source="type" choices={[
      { id: 'LITIGATION', name: 'LITIGATION' },
      { id: 'LABOR', name: 'LABOR' },
      { id: 'CORPORATE', name: 'CORPORATE' },
    ]} />
    <SelectInput label="Access" source="accessOn" choices={[
      { id: 'FREE', name: 'FREE' },
      { id: 'BASIC', name: 'BASIC' },
      { id: 'ADVANCE', name: 'ADVANCE' },
    ]} />
  </Filter>
);

const ChecklistPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const ChecklistList = props => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} filters={<ChecklistFilter />} pagination={<ChecklistPagination />} exporter={false} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="venue" />
        <NumberField source="numberOfCopies" />
        <TextField source="prayer" />
        <TextField source="filing_type.code" label="Filing Type" />
        <TextField source="accessOn" />
        <TextField source="status" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        {permissions['write checklist'] ? <EditButton /> : null}
        {permissions['write checklist'] ? <ActivateButton table='checklist' name='title' /> : null}
      </Datagrid>
    </List>
  );
}

const transform = data => {
  return ({
    ...data,
    filing_type_id: data.filing_type.id
  })
};

export const ChecklistEdit = props => (
  <Edit title={<ChecklistTitle />} {...props} undoable={false} transform={transform}>
    <SimpleForm style={{flex: 1}} variant="standard" toolbar={<CustomToolbar />}>
      <TextInput source="id" disabled />
      <TextInput source="name" validate={[required()]} />
      <TextInput source="venue" validate={[required()]} />
      <NumberInput source="numberOfCopies" />
      <TextInput source="prayer" />
      <ReferenceInput label="Filing Fee Type" source="filing_type.id" reference="filing-type">
        <SelectInput optionText="code" />
      </ReferenceInput>
      <SelectInput label="Access" source="accessOn" choices={[
        { id: 'FREE', name: 'FREE' },
        { id: 'BASIC', name: 'BASIC' },
        { id: 'ADVANCE', name: 'ADVANCE' },
      ]} validate={[required()]} />
      <ArrayInput source="requirements" validate={[required()]}>
        <SimpleFormIterator>
          <TextField source="id" label="" />
          <TextInput source="index" fullWidth label="Index" />
          <MarkdownInput source="subIndex" fullWidth label="Sub Index" />
          <TextInput source="additionalDescription" fullWidth label="Additional Description" />
          <BooleanInput source="isOptional" label="Optional" defaultValue={false}  />
        </SimpleFormIterator>
      </ArrayInput>
      <TextField source="created_at" />
      <TextField source="updated_at" />
    </SimpleForm>
  </Edit>
);

export const ChecklistCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
      notify(`Element created`)
      redirect(`/${props.resource}`);
      refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm variant="standard">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="venue" validate={[required()]} />
        <NumberInput source="numberOfCopies" />
        <TextInput source="prayer" />
        <ReferenceInput label="Filing Fee Type" source="filing_type_id" reference="filing-type">
          <SelectInput optionText="code" />
        </ReferenceInput>
        <SelectInput label="Access" source="accessOn" choices={[
          { id: 'FREE', name: 'FREE' },
          { id: 'BASIC', name: 'BASIC' },
          { id: 'ADVANCE', name: 'ADVANCE' },
        ]} validate={[required()]} />
        <ArrayInput source="requirements" validate={[required()]}>
          <SimpleFormIterator>
            <TextInput source="index" fullWidth label="Index" />
            <MarkdownInput source="subIndex" fullWidth label="Sub Index" />
            <TextInput source="additionalDescription" fullWidth label="Additional Description" />
            <BooleanInput source="isOptional" label="Optional" defaultValue={false} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}