import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRedirect, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ActivateButton = ({ record, table, name }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let isActive, action, btnColor
  if (record.status === 'ACTIVE') {
    isActive = false
    action = 'deactivate'
    btnColor = {color: 'white', backgroundColor: "#d32f2f"}
  }
  else {
    isActive = true
    action = 'activate'
    btnColor = {color: 'white', backgroundColor: "#00796b"}
  }

  const ConfirmActivate = () => {
    return (
      <div>
        <Button size="small" variant="contained" style={btnColor} onClick={handleClickOpen}>
          {action}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{(action + ' ' + record[name]).toUpperCase()}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to <b>{action.toUpperCase()}</b> "{record[name]}"?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={activate} color="primary" autoFocus>
              {action}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const activate = () => {
    dataProvider[action](table, { id: record.id, data: { isActive: isActive } })
    .then(response => {
        refresh();
        notify(`${table} ${action}d`);
    })
    .catch(error => {
        notify(`Error: ${error.message}`, 'warning');
    })
    setOpen(false)
  }
  
  return <ConfirmActivate />;
};

export default ActivateButton;