import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  show: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  permission: {
    textTransform: 'uppercase',
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  }
}));

const CustomModal = ({ record, source }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const PermissionBooleanColor = props => {
    if (props.bool === true) {
      return (
        <span className={classes.success}>YES</span>
      )
    }
    else {
      return (
        <span className={classes.error}>NO</span>
      )
    }
  }

  return (
    <div>
      <Button startIcon={<VisibilityIcon />} size="small" color="primary" onClick={handleOpen}>show</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h3 id="transition-modal-title">{source.toUpperCase()}</h3>
              {Object.entries(record[source]).map(item => (
                <div className={classes.permission} key={item}>{item[0]}: <PermissionBooleanColor bool={item[1]} /></div>
              ))}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default CustomModal;