import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, required, useNotify, useRefresh, useRedirect, Pagination, ArrayField, ImageInput, ImageField, FileInput, FormDataConsumer } from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import PreviewAreaImages from '../PreviewAreaImages';
import ViewPlaceMap from '../ViewPlaceMap';
import SetPlaceMap from '../SetPlaceMap';
import SetPlaceMapNew from '../SetPlaceMapNew';
import ActivateButton from '../ActivateButton';
import Cookies from 'js-cookie';

const PlaceTitle = ({ record }) => {
  return <span>Place {record ? `"${record.name}"` : ''}</span>;
};

const PlacePanel = ({ id, record, resource }) => (
  <div style={{ flex: 1, flexDirection: 'row', width: 1000 }}>
    <div>
      <b>Contacts:</b> <br />
      {
        record.contacts ?
        record.contacts.map(x => <div>{x.name ? x.name : null} {x.number}</div>) : null
      }
    </div>
    <div>
      <b>Emails:</b> <br />
      {
        record.emails ?
        record.emails.map(x => <div>{x.name ? x.name : null} {x.email}</div>) : null
      }
    </div>
  </div>
);

const PlaceFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
    <SelectInput label="Status" source="status" choices={[
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'CREATED', name: 'CREATED' },
      { id: 'INACTIVE', name: 'INACTIVE' },
    ]} />
    <SelectInput label="Type" source="type" choices={[
      { id: 'COURT', name: 'COURT' },
      { id: 'OFFICE', name: 'OFFICE' },
    ]} />
  </Filter>
);

const PreviewImage = ({ record, source }) => {
  if (typeof (record) == "string") {
      record = {
          [source]: record
      }
  }
  return <ImageField record={record} source={source} />
}

const PlacePagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const PlaceList = props => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} filters={<PlaceFilter />} pagination={<PlacePagination />} exporter={false} {...props}>
      <Datagrid expand={<PlacePanel />}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="address" />
        <ViewPlaceMap source="geoPin" label="Geo Pin" />
        <TextField source="place_type.code" label="Type" />
        <TextField source="status" />
        <PreviewAreaImages source="pictures" label="Images" />
        <TextField source="accessOn" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        {permissions['write place'] ? <EditButton /> : null}
        {permissions['write place'] ? <ActivateButton table='place' name='title' /> : null}
      </Datagrid>
    </List>
  );
}

const ImagePreview = record => {
  return (
    <img src={record.record} style={{ height: 150 }} />
  )
}

export const PlaceEdit = props => {
  const transform = data => ({
    ...data,
    contacts: data.contacts ? JSON.stringify(data.contacts) : null,
    emails: data.emails ? JSON.stringify(data.emails) : null,
    pictures: data.pictures ? JSON.stringify(data.pictures) : null,
    place_type_id: data.place_type.id
  });

  return (
    <Edit title={<PlaceTitle />} {...props} undoable={false} transform={transform}>
      <SimpleForm style={{flex: 1}} variant="standard" toolbar={<CustomToolbar />}>
        <TextInput source="id" disabled />
        <TextInput source="name" validate={[required()]} />
        <TextInput source="address" validate={[required()]} />
        <TextInput source="specificAddress" />
        {/* <SelectInput label="Type" source="place_type_id" choices={[
          { id: 1, name: 'Court' },
          { id: 2, name: 'Office' },
        ]} validate={[required()]} /> */}
        <ReferenceInput label="Place Type" source="place_type.id" reference="place-type">
          <SelectInput optionText="code" />
        </ReferenceInput>
        <SetPlaceMap source="geoPin" />
        <ArrayInput source="contacts">
          <SimpleFormIterator>
            <TextInput source="name" label="name" />
            <TextInput source="number" label="number" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="emails">
          <SimpleFormIterator>
            <TextInput source="name" label="name" />
            <TextInput source="email" label="email" />
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput source="accessOn" choices={[
          { id: 'FREE', name: 'FREE' },
          { id: 'BASIC', name: 'BASIC' },
          { id: 'ADVANCE', name: 'ADVANCE' },
        ]} validate={[required()]} />
        <ArrayInput source="pictures">
          <SimpleFormIterator disableAdd>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                  return (
                      <ImagePreview record={scopedFormData} />
                  );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        {/* <FileInput source="newPictures" accept="image/png, image/jpeg" label="New Pictures" placeholder={<p>Drop your file here</p>}>
          <ImageField source="newPictures" title="title" />
        </FileInput> */}
        <ImageInput {...props} accept="image/*" source="newPictures" multiple={true} maxSize={5000000}>
          <ImageField source="newPictures" title="title"/>
        </ImageInput>
        <TextField source="created_at" />
        <TextField source="updated_at" />
      </SimpleForm>
    </Edit>
  )
};

export const PlaceCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
      notify(`Element created`)
      redirect(`/${props.resource}`);
      refresh();
  };

  const transform = data => ({
    ...data,
    contacts: data.contacts ? JSON.stringify(data.contacts) : null,
    emails: data.emails ? JSON.stringify(data.emails) : null,
    place_type_id: data.place_type.id
  });

  return (
    <Create {...props} onSuccess={onSuccess} transform={transform}>
      <SimpleForm variant="standard">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="address" validate={[required()]} />
        <TextInput source="specificAddress" />
        {/* <SelectInput label="Type" source="place_type_id" choices={[
          { id: 1, name: 'Court' },
          { id: 2, name: 'Office' },
        ]} validate={[required()]} /> */}
        <ReferenceInput label="Place Type" source="place_type.id" reference="place-type">
          <SelectInput optionText="code" />
        </ReferenceInput>
        <SetPlaceMapNew source="geoPin" />
        <ArrayInput source="contacts">
          <SimpleFormIterator>
            <TextInput source="name" label="name" />
            <TextInput source="number" label="number" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="emails">
          <SimpleFormIterator>
            <TextInput source="name" label="name" />
            <TextInput source="email" label="email" />
          </SimpleFormIterator>
        </ArrayInput>
        <ImageInput {...props} accept="image/*" source="pictures" multiple={true} maxSize={5000000}>
          <ImageField source="pictures" title="title"/>
        </ImageInput>
        <SelectInput source="accessOn" choices={[
          { id: 'FREE', name: 'FREE' },
          { id: 'BASIC', name: 'BASIC' },
          { id: 'ADVANCE', name: 'ADVANCE' },
        ]} validate={[required()]} />
      </SimpleForm>
    </Create>
  )
}