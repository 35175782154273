import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRedirect, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PlaceIcon from '@material-ui/icons/Place';
import GoogleMapReact from 'google-map-react';

const Marker = ({ text }) => (
  <div style={{ position: 'absolute', bottom: -5, right: 0, left: -17 }}>
    <PlaceIcon fontSize="large" color="primary" stroke={"white"} stroke-width={1} />
  </div>
);

const ViewPlaceImages = ({ record, source }) => {
  const [open, setOpen] = React.useState(false);

  const marker = JSON.parse(record[source])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button startIcon={<PlaceIcon />} size="small" color="primary" onClick={handleClickOpen}>
        Show
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth = {'md'}
        style={{ margin: 50 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{record.name}</DialogTitle>
        <DialogContent style={{ height: '100vh', width: '100%', padding: 0 }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBu7G17rOHAwiSbn8-WoyWAPwxg1MTD_Mw', libraries: ['places'] }}
            options={{
              styles: [
                {
                  featureType: 'all',
                  stylers: [{ visibility: 'on' }],
                }
              ]
            }}
            defaultCenter={{lat: marker.lat, lng: marker.lng}}
            defaultZoom={20}
          >
            <Marker
              lat={marker.lat}
              lng={marker.lng}
              text="My Marker"
            />
          </GoogleMapReact>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewPlaceImages;