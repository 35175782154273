import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FilingFeeCalculator from './FilingFeeCalculator';

import { useFormState } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FilingFeeTable = ({ record, source }) => {
  const { values } = useFormState();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [hasAdditional, setHasAdditional] = React.useState(false)

  const [rowData, setRowData] = React.useState({
    from: null,
    to: null,
    amountType: 'AMOUNT_ONLY',
    amount: null,
    formula: []
  })
  const [rowName, setRowName] = React.useState('')
  const [rowIndex, setRowIndex] = React.useState(null)

  const handleClickOpen = (data, index) => {
    if (data) {
      if (data.formula) {
        setRowData({...data, formula: JSON.parse(JSON.stringify(data.formula))})
      }
      else {
        setRowData({...data, formula: []})
      }
      setRowIndex(index)
      if (data.additionalAmountType) {
        setHasAdditional(true)
      }
      else {
        setHasAdditional(false)
      }
    }
    else {
      setRowData({
        from: null,
        to: null,
        amountType: 'AMOUNT_ONLY',
        amount: null,
        formula: []
      })
      setRowIndex(null)
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDelete = (row) => {
    setRowData(row)
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const isEmptyOrSpaces = str => {
    return str === null || str.match(/^ *$/) !== null;
  }

  const emptyToNull = str => {
    if (isEmptyOrSpaces(str)) {
      return null
    }
    else {
      return str
    }
  }

  const addClaimTableRow = () => {
    setRowData({...rowData, claimTable: [...rowData.claimTable, {
      index: null,
      from: null,
      to: null,
      amount: null
    }]})
  }

  const deleteClaimTableRow = (row) => {
    let data = rowData.claimTable.filter(x => x != row)
    setRowData({...rowData, claimTable: data})
  }

  const deleteRow = () => {
    record[source] = record[source].filter(x => x != rowData)
    values[source] = record[source]
    handleCloseDelete()
  }

  const save = (data) => {
    if (data.amountType == 'AMOUNT_ONLY') {
      delete data.formula

      if (isEmptyOrSpaces(data.amount)) {
        alert('Please fill in the required fields.')
        return
      }
    }
    else if (data.amountType == 'FORMULA') {
      delete data.amount

      if (data.formula.length < 1) {
        alert('Please fill in the required fields.')
        return
      }

      let lastVar = data.formula[data.formula.length - 1]
      let openParentCount = data.formula.filter((x) => (x === '(')).length;
      let closeParentCount = data.formula.filter((x) => (x === ')')).length;

      if (lastVar == '/' || lastVar == '*' || lastVar == '-' || lastVar == '+' || lastVar == '(') {
        alert('Invalid formula.')
        return
      }
      else if (openParentCount != closeParentCount) {
        alert('Invalid formula.')
        return
      }
    }

    if (data.additionalAmountType == 'EXCESS_PERCENT_OF') {
      delete rowData.excessConditionForEvery
      delete rowData.excessAmountForEvery
      delete rowData.multiplyTo
    }
    else if (data.additionalAmountType == 'EXCESS_FOR_EVERY') {
      delete rowData.percentOf
      delete rowData.multiplyTo
    }
    else if (data.additionalAmountType == 'EXCESS_MULTIPLY_TO') {
      delete rowData.percentOf
      delete rowData.excessConditionForEvery
      delete rowData.excessAmountForEvery
    }
    else if (data.additionalAmountType == 'PERCENT_OF') {
      delete rowData.excessOf
      delete rowData.excessConditionForEvery
      delete rowData.excessAmountForEvery
      delete rowData.multiplyTo
    }
    else if (data.additionalAmountType == 'MULTIPLY_TO') {
      delete rowData.excessOf
      delete rowData.percentOf
      delete rowData.excessConditionForEvery
      delete rowData.excessAmountForEvery
    }
    else {
      delete rowData.excessOf
      delete rowData.percentOf
      delete rowData.excessConditionForEvery
      delete rowData.excessAmountForEvery
      delete rowData.multiplyTo
    }

    data.from = emptyToNull(data.from)
    data.to = emptyToNull(data.to)

    if (data.id) {
      record[source] = record[source].map(x => {
        if (x.id == data.id) {
          return data
        }
        else {
          return x
        }
      });
      values[source] = record[source]
    }
    else if (data && rowIndex) {
      record[source][rowIndex] = data
      values[source][rowIndex] = record[source][rowIndex]
    }
    else {
      record[source].push(data)
      values[source] = record[source]
    }

    setOpen(false);
  }

  const classes = useStyles();

  if (!record[source]) {
    record[source] = []
  }

  return (
    <div>
      <div style={{ margin: 10 }}>
        <b>Conditional Table</b>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Amount Type</TableCell>
              <TableCell align="right"> 
                <Button startIcon={<AddIcon />} size="small" color="primary" onClick={() => handleClickOpen()}>
                  New
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.conditionalTable.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.from}</TableCell>
                <TableCell>{row.to}</TableCell>
                <TableCell>{row.amountType}</TableCell>
                <TableCell align="right">
                  <Button startIcon={<CreateIcon />} size="small" color="primary" onClick={() => handleClickOpen(row, index)}>
                    Edit
                  </Button>
                  <Button style={{ float: 'right', color: '#f44336' }} startIcon={<DeleteIcon />} size="small" onClick={() => handleClickOpenDelete(row)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
        <DialogTitle id="form-dialog-title">{rowData ? `Edit` : 'Create'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="From"
            type="text"
            value={rowData.from}
            onChange={event => setRowData({...rowData, from: event.target.value})}
            type="number"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="To"
            type="text"
            value={rowData.to}
            onChange={event => setRowData({...rowData, to: event.target.value})}
            type="number"
            fullWidth
          />
          <FormControlLabel className={classes.formControl}
            control={
              <Switch
                checked={hasAdditional}
                onChange={event => {
                  setHasAdditional(!hasAdditional)
                  if (!hasAdditional) {
                    setRowData({...rowData, amountType: 'AMOUNT_ONLY', additionalAmountType: null })
                  }
                }}
                color="primary"
              />
            }
            label="Additional Amount"
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Amount Type</InputLabel>
            <Select
              value={rowData.amountType}
              onChange={event => setRowData({...rowData, amountType: event.target.value})}
            >
              <MenuItem value='AMOUNT_ONLY'>AMOUNT ONLY</MenuItem>
              { !hasAdditional ? <MenuItem value='FORMULA'>FORMULA</MenuItem> : null }
            </Select>
          </FormControl>

          { hasAdditional ? <FormControl className={classes.formControl}>
            <InputLabel>Additional Amount Type</InputLabel>
            <Select
              value={rowData.additionalAmountType}
              onChange={event => setRowData({...rowData, additionalAmountType: event.target.value})}
            >
              <MenuItem value='EXCESS_PERCENT_OF'>EXCESS PERCENT OF</MenuItem>
              <MenuItem value='EXCESS_FOR_EVERY'>EXCESS FOR EVERY</MenuItem>
              <MenuItem value='EXCESS_MULTIPLY_TO'>EXCESS MULTIPLY TO</MenuItem>
              <MenuItem value='PERCENT_OF'>PERCENT OF</MenuItem>
              <MenuItem value='MULTIPLY_TO'>MULTIPLY TO</MenuItem>
            </Select>
          </FormControl> : null }

          { rowData.amountType == 'AMOUNT_ONLY' ? 
              <TextField
                margin="dense"
                id="name"
                label="Amount"
                type="number"
                value={rowData.amount}
                onChange={event => setRowData({...rowData, amount: event.target.value})}
                fullWidth
                required
              />
            : null }

          { rowData.amountType == 'FORMULA' ? 
              <FilingFeeCalculator formula={rowData.formula} record={record[source]} type="conditional" />
            : null }

          { hasAdditional && (rowData.additionalAmountType == 'EXCESS_PERCENT_OF' || rowData.additionalAmountType == 'EXCESS_FOR_EVERY' || rowData.additionalAmountType == 'EXCESS_MULTIPLY_TO') ? 
              <TextField
                margin="dense"
                label="Excess Of"
                type="number"
                value={rowData.excessOf}
                onChange={event => setRowData({...rowData, excessOf: event.target.value})}
                fullWidth
                required
              />
            : null }

          { hasAdditional && (rowData.additionalAmountType == 'EXCESS_PERCENT_OF' || rowData.additionalAmountType == 'PERCENT_OF') ? 
              <TextField
                margin="dense"
                label="Percent Of"
                type="number"
                value={rowData.percentOf}
                onChange={event => setRowData({...rowData, percentOf: event.target.value})}
                fullWidth
                required
              />
            : null }

          { hasAdditional && (rowData.additionalAmountType == 'EXCESS_FOR_EVERY') ? 
              <TextField
                margin="dense"
                label="Excess Condition For Every"
                type="number"
                value={rowData.excessConditionForEvery}
                onChange={event => setRowData({...rowData, excessConditionForEvery: event.target.value})}
                fullWidth
                required
              />
            : null }

          { hasAdditional && (rowData.additionalAmountType == 'EXCESS_FOR_EVERY') ? 
              <TextField
                margin="dense"
                label="Excess Amount For Every"
                type="number"
                value={rowData.excessAmountForEvery}
                onChange={event => setRowData({...rowData, excessAmountForEvery: event.target.value})}
                fullWidth
                required
              />
            : null }

          { hasAdditional && (rowData.additionalAmountType == 'EXCESS_MULTIPLY_TO' || rowData.additionalAmountType == 'MULTIPLY_TO') ? 
              <TextField
                margin="dense"
                label="Multiply To"
                type="number"
                value={rowData.multiplyTo}
                onChange={event => setRowData({...rowData, multiplyTo: event.target.value})}
                fullWidth
                required
              />
            : null }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save(rowData)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {rowName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteRow} style={{ color: '#f44336' }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <br />
    </div>
  );
}

export default FilingFeeTable;