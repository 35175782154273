import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import CustomToolbar from '../CustomToolbar';
import { List, Datagrid, TextField, ReferenceField, EditButton, SimpleForm, SimpleList, TextInput, ReferenceInput, SelectInput, Edit, Create, Filter, NumberField, DateField, ArrayInput, SimpleFormIterator, DateInput, EmailField, BooleanField, BooleanInput, PasswordInput, required, useNotify, useRefresh, useRedirect, Pagination } from 'react-admin';
import Cookies from 'js-cookie';

const FeepercentageTitle = ({ record }) => {
  return <span>LEGAL RESEARCH FEE PERCENTAGE</span>;
};

const FeepercentageFilter = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="searchValue" alwaysOn />
  </Filter>
);

const FeepercentagePagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const FeepercentageList = props => {
  const permissions = JSON.parse(Cookies.get('permissions'));
  return (
    <List bulkActionButtons={false} filters={<FeepercentageFilter />} pagination={<FeepercentagePagination />} exporter={false} {...props}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="value" />
        {permissions['write filing fee'] ? <EditButton /> : null}
      </Datagrid>
    </List>
  );
}

export const FeepercentageEdit = props => (
  <Edit title={<FeepercentageTitle />} {...props} undoable={false}>
    <SimpleForm style={{flex: 1}} variant="standard">
      <TextInput source="value" validate={[required()]} />
    </SimpleForm>
  </Edit>
);