import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Cookies from 'js-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiUrl } from './config';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
      minHeight: 200,
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
    },
    plans: {
      minWidth: 350,
      minHeight: 200,
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

export default () => {
  const classes = useStyles();
  const [dashboard, setDashboard] = React.useState(null);

  const config = {
    headers: {
      'Authorization': 'Bearer ' + Cookies.get('token'),
      'Accept': 'application/json'
    }
  }

  React.useEffect(() => {
    axios.get(`${apiUrl}/admin/dashboard`, config)
    .then(res => {
      setDashboard(res.data.message)
    })
    .catch(err => {
    })
  }, [])

  if (!dashboard) {
    return (
      <Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <CircularProgress />
      </Box>
    )
  }
  else {
    return (
      <div>
        <Card style={{ marginLeft: 10, marginRight: 10 }}>
          <CardHeader title={`${Cookies.get('username')} - LegalAide Admin`} style={{ backgroundColor: '#002038', color: 'white' }} />
        </Card>
        <br />
        <Box display="flex" flexWrap="wrap">
          <Box>
            <Card className={classes.root}>
              <CardContent style={{ backgroundColor: '#002038', color: 'white' }}>
                <Typography variant="h5" component="h2">
                  TOTAL USERS
                </Typography>
              </CardContent>
              <CardContent>
                <br />
                <Typography variant="h2" component="h2">
                  <center>{dashboard.totalUsers}</center>
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box>
            <Card className={classes.root}>
              <CardContent style={{ backgroundColor: '#002038', color: 'white' }}>
                <Typography variant="h5" component="h2">
                  TOTAL ADMINS
                </Typography>
              </CardContent>
              <CardContent>
                <Typography component="h2">
                  ACTIVE: {dashboard.totalAdmins.ACTIVE}
                </Typography>
                <Typography component="h2">
                  INACTIVE: {dashboard.totalAdmins.INACTIVE}
                </Typography>
                <Typography component="h2">
                  CREATED: {dashboard.totalAdmins.CREATED}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <br />
        <div style={{ marginLeft: 10, fontSize: 21, fontWeight: 'bold' }}>
          PLANS
        </div>
        <hr style={{ margin: 10, backgroundColor: '#C4C4C4', height: '4px', border: 'none' }} />
        <Box display="flex" flexWrap="wrap">
          {dashboard.activeUserOnPlan.map(item => (  
            <Box>
              <Card className={classes.plans}>
                <CardContent style={{ marginBottom: -20, backgroundColor: '#002038', color: 'white' }}>
                  <Typography variant="h5" component="h2">
                    {item.id}
                  </Typography>
                </CardContent>
                <CardContent>
                  <br />
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.name}
                  </Typography>
                  <Typography component="h4">
                    User Count: {item.userCount} <br />
                    Percent: {item.percent}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      </div>
    )
  }
}