import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: '0.7em',
        paddingLeft: 2,
    },
});

const CustomUrlField = ({ record = {}, source }) => {
    const classes = useStyles();
    if (record[source]) {
        return (
            <a href={record[source]} title={record[source]} target="_blank" className={classes.link}>
                {/* {record[source]} */}
                <LaunchIcon className={classes.icon} />
            </a>
        );
    }
    else {
        return (
            <span>
                
            </span>
        );
    }
}

export default CustomUrlField;